<template>
  <div>
    <h3 ref="ia">
      Hooke's Law
    </h3>
    <p>
      Hooke's law, named after British physicist Robert Hooke, is a fundamental principle in the area of mechanics of materials.
      Hooke's law relates the deformation of a spring to the load applied.
      Hooke, while experimenting with springs, found that the force required to stretch or compress a spring is linearly proportional to the extent of deformation.
      Mathematically, Hooke's law can be expressed as:
      $$ F \propto \delta $$
      where \(\delta = l - l_0\) is the stretching or compression of the spring (deformed length minus original length).
      Expressed as an equality (by introducing a proportionality constant \(k\)):
      $$ F = k (l- l_0)$$
      where \(F\) is the load applied, \(l\) is the deformed length and \(l_0\) is the original length of spring. The proportionality factor \(k\) is known as spring constant.
    </p>
    <p />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Oscillations of Spring-Mass System';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Hooke\'s Law', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Implications of Hooke\'s law', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'PlayGraph: Hooke\'s Experiment',img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  beforeDestroy(){
  Boxes.destroy();
  },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
