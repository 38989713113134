const Boxes = {
  box1: function () {
    var board = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox: [-2, 12, 14, -4], keepaspectratio: true, axis:false, grid:true, ticks:false, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
    //
    board.options.board.minimizeReflow = 'none';
    board.options.text.highlight=false;
    board.options.text.fixed=true;
    board.options.image.highlight=false;
    board.options.text.cssDefaultStyle='fontFamily:Oswald';
    board.options.line.highlight=false;
    board.options.arc.highlight=false;
    board.options.point.highlight=false;
    board.options.point.showInfoBox=false;
    //
    var resize = function () {
          board.resizeContainer(board.containerObj.clientWidth, board.containerObj.clientHeight, true);
          board.fullUpdate();
          };
    window.onresize = resize;
    //
    let xAxis =board.create('axis', [[0, -1],[5, -1]], { strokeColor:'grey',
      highlight:false, strokeWidth: ()=>Math.round(2*board.canvasWidth/800.),
      withLabel:true, name: '', straightFirst:false, straightLast:true, lastArrow:false,
        label: {
          display:'internal',
          fontSize:function(){return 24*board.canvasHeight/800},
          CssStyle: 'fontFamily:Oswald;fontWeight:bold',
          position: 'rt',
          offset: [-20, 20]}});
  //
    var line = board.create('line', [[0,0], [100, 0]], {visible: false, straightFirst: false, straightLast: false});
    //
    var point = board.create('glider', [8, 0, line], {name: '', size:function(){return 45*board.canvasHeight/800}, face:'square'});
    //
    var ktext= board.create('text',[function(){return point.X()/2.0}, 1.5, 'k'],{cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var mtext= board.create('text',[function(){return point.X()-0.25}, 0, 'm'],{cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*board.canvasWidth/500.)}, fixed:true});
    //
    var isInDragMode = false;
    //
    var springHangup = board.create('point', [0, 0], {color: 'black', name: '', fixed: true, visible:false});
    //
    var i=0;
    var numberOfSpringRings = 100;
    var springRings = [];
    //
    for(i=0; i<numberOfSpringRings; i++) {
        springRings[i] = board.create('point', [function(i){return function(){return (point.X()/numberOfSpringRings)*i};}(i), function(i) {return function() { return 0.5*Math.sin(5000*i)};}(i) ], {withLabel: false, visible:false, color: 'black', size: 1});
          if(i>0)
            board.create('segment', [springRings[i-1], springRings[i]], {color: 'blue', strokeWidth: 2});
          };
    //
  //const c = 0.001;
//   var c = board.create('slider',[[2.,7.],[9.,7.],[0.0, 0.01, 0.05]],{unitLabel:' 1/s',baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'c',size:function(){return 8*board.canvasHeight/800},face:'circle', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(14*board.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    //
    var m = board.create('slider',[[2.,5.],[5.,5.],[0.25, 0.5, 1]],{unitLabel:' kg',snapWidth:0.25, baseline:{strokeWidth:7, strokeColor:'grey', lineCap:'round'},highline:{strokeWidth:7, strokeColor:'black', lineCap:'round'}, name:'&nbsp; &nbsp; m',size:function(){return 8*board.canvasHeight/800},face:'circle', fillColor:'red',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(14*board.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    //
    var k = board.create('slider',[[2.,3.],[5.,3.],[0.25, 0.25, 1]],{unitLabel:' N/m', snapWidth:0.25, baseline:{strokeWidth:7, strokeColor:'grey', lineCap:'round'},highline:{strokeWidth:7, strokeColor:'black', lineCap:'round'}, name:'&nbsp; &nbsp; k',size:function(){return 8*board.canvasHeight/800},face:'circle', fillColor:'blue',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(14*board.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
    //
    var vbase=board.create('polygon',[[0,-4.75],[25,-4.75],[25,-0.95],[0, -0.95]], {fillColor:'lightgrey',fillOpacity:0.6, withLines:false, vertices:{visible:false}});
    //
    var hbase=board.create('polygon',[[-5,-4.75],[0,-4.75],[0,15],[-5, 15]], {fillColor:'lightgrey', fillOpacity:0.6, withLines:false, vertices:{visible:false}});
    board.create('segment', [springHangup, springRings[0]], {color: 'black', strokeWidth: 1});
    //
    board.create('segment', [springRings[numberOfSpringRings-1], point], {strokeColor: 'black', strokeWidth: 1});
    //
    var map= board.create('text',[6, 10.5, '<b>Spring-Mass System</b>'],{anchorX:'middle', cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(32*board.canvasWidth/800.)}, fixed:true});
    //
    board.create('point', [function(){return point.X()-0.1}, 1.5-3], {name: '', color:'black', size:function(){return 4*board.canvasHeight/800}, face:'triangleright'});
//
    board.create('point', [0.1, 1.5-3], {name: '', color:'black', size:function(){return 4*board.canvasHeight/800}, face:'triangleleft', fixed:true});
    //
    board.create('segment', [[function(){return point.X()}, 1.5-3],[0, 1.5-3] ], {fixed:true, strokeColor:'black', strokeWidth:1});
    //
    function startAnimation(startY) {
      point.moveAlong(function() {
    var f = function(t, x) {
            return [x[1],  -(k.Value()/m.Value()) * (x[0]-8)];};
    var area = [0, 200];
    var numberOfEvaluations = (area[1] - area[0]) * 100;
    var data = JXG.Math.Numerics.rungeKutta('heun', [startY, 0], area, numberOfEvaluations, f);
    var duration = 20 * 1e3;
    return function(t) {
        if (t >= duration)
            return NaN;
        return [data[Math.floor(t / duration * numberOfEvaluations)][0], startY];}
        }());}
//
    function hook() {
      if(!isInDragMode) {
    if(board.mode === board.BOARD_MODE_DRAG) {
        board.stopAllAnimation();
        isInDragMode = true;
    }
    }
    if(isInDragMode) {
    if(board.mode !== board.BOARD_MODE_DRAG) {
        isInDragMode = false;
        startAnimation(point.X());
    }
    }
    }
    board.addHook(hook);
//    startAnimation(10);
},
}
export default Boxes;
